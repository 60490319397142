import React, { useEffect, useState } from 'react';

import Collapsible from 'react-collapsible';
import { Link } from 'gatsby';
import styled from 'styled-components';

const CategoryDrawer = ({ allPosts, activePost, activePostTag }) => {
	const [windowSize, setWindowSize] = useState(0);

	useEffect(() => {
		resizeListener();
		window.addEventListener('resize', resizeListener);
		return () => window.removeEventListener('resize', resizeListener);
	}, []);

	const resizeListener = () => {
		setWindowSize(window.innerWidth);
	};

	const categories = [
		{
			title: 'Телевизия',
			tag: 'tv',
			posts: allPosts.filter(({ node }) => {
				return node.acf.category === 'tv';
			})
		},
		{
			title: 'Интернет',
			tag: 'internet',
			posts: allPosts.filter(({ node }) => {
				return node.acf.category === 'internet';
			})
		},
		{
			title: 'Пакетни услуги',
			tag: 'packages',
			posts: allPosts.filter(({ node }) => {
				return node.acf.category === 'packages';
			})
		},
		{
			title: 'Интернет услуги',
			tag: 'internet-services',
			posts: allPosts.filter(({ node }) => {
				return node.acf.category === 'internet-services';
			})
		},
		{
			title: 'Клиенти',
			tag: 'clients',
			posts: allPosts.filter(({ node }) => {
				return node.acf.category === 'clients';
			})
		},
		{
			title: 'Мобилно приложение',
			tag: 'app',
			posts: allPosts.filter(({ node }) => {
				return node.acf.category === 'app';
			})
		},
		{
			title: 'MAX',
			tag: 'hbo-max',
			posts: allPosts.filter(({ node }) => {
				return node.acf.category === 'hbo-max';
			})
		}
	];

	return (
		<DrawerBySize
			activePost={activePost}
			activePostTag={activePostTag}
			windowSize={windowSize}
			categories={categories}
		/>
	);
};

const DrawerWrapper = styled.div`
	background: #f4f5fa;
	/* background: grey; */
	border-radius: 16px;
	padding: 1rem;
	max-width: 320px;

	@media screen and (min-width: 992px) and (max-width: 1350px) {
		max-width: 280px;
		margin-right: 2rem;
	}

	@media screen and (max-width: 991px) {
		max-width: 100%;
		padding-bottom: 0px;
		margin-bottom: 1rem;
		margin-top: -3rem;
	}
`;

const Trigger = ({ title, isOpen }) => (
	<button className="clear w-100" type="button">
		<div className="d-flex justify-content-between">
			<h3>{title}</h3>
			<p>
				<span
					className={
						'icon ' +
						(isOpen ? 'drawer-category-close' : 'drawer-category-open')
					}
				></span>
			</p>
		</div>
	</button>
);

const CategoryCollapsible = ({ title, posts, activePost, isOpen }) => {
	return (
		<div className="my-2">
			<Collapsible
				open={isOpen}
				trigger={<Trigger title={title} isOpen={false} />}
				triggerWhenOpen={<Trigger title={title} isOpen={true} />}
			>
				<ul className="category-cards text-left">
					{posts.map(({ node }, index) => {
						const isActive =
							`/help-center/${node.acf.category}/${node.slug}` ===
							activePost;
						return (
							<li key={index} className="my-1 row">
								<div className="col-1 m-0 p-0">
									<span
										className={
											'icon category-arrow ' + (isActive && 'green')
										}
									></span>
								</div>
								<div className="col-10 m-0 p-0">
									<Link
										to={`/help-center/${node.acf.category}/${node.slug}`}
									>
										<span
											className={isActive ? 'bold-text' : 'none'}
											dangerouslySetInnerHTML={{
												__html: node.title
											}}
										></span>
									</Link>
								</div>
							</li>
						);
					})}
				</ul>
			</Collapsible>
			<hr className="normal-bottom mt-0" />
		</div>
	);
};

const DrawerBySize = ({ windowSize, activePost, activePostTag, categories }) => {
	if (windowSize >= 992) {
		return (
			<div className="d-flex justify-content-center">
				<DrawerWrapper>
					{categories.map(({ title, tag, posts }, index) => (
						<div key={title + index}>
							<CategoryCollapsible
								key={title + index}
								title={title}
								posts={posts}
								isOpen={activePostTag === tag}
								activePost={activePost}
							/>
						</div>
					))}
				</DrawerWrapper>
			</div>
		);
	} else {
		return (
			<div className="d-flex justify-content-center">
				<DrawerWrapper>
					<Collapsible
						open={false}
						trigger={<Trigger title="Категории" isOpen={false} />}
						triggerWhenOpen={<Trigger title="Категории" isOpen={true} />}
					>
						<DrawerWrapper>
							{categories.map(({ title, tag, posts }, index) => (
								<div key={title + index}>
									<CategoryCollapsible
										key={title + index}
										title={title}
										posts={posts}
										isOpen={activePostTag === tag}
										activePost={activePost}
									/>
								</div>
							))}
						</DrawerWrapper>
					</Collapsible>
				</DrawerWrapper>
			</div>
		);
	}
};

export default CategoryDrawer;

