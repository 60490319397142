import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import ShareTab from '../components/shared/share-tab';
import { Breadcrumbs } from '../components/shared/breadcrumbs';
import SEO from '../components/seo';
import { BgWrapper } from '../components/shared/components';
import CategoryDrawer from '../components/help/CategoryDrawer';

const breadcrumbs = [
	{
		text: 'Начало',
		link: '/'
	},
	{
		text: 'FAQ',
		link: '/help-center/'
	}
];

export default ({ data }) => {
	const post = data.post;
	const allPosts = data.allPosts.edges;
	let b = breadcrumbs.slice();
	if (b[b.length - 1].text !== post.title) b.push({ text: post.title });

	return (
		<BgWrapper bg={data.bg.childImageSharp.fluid.src} position="top">
			<Layout activeTab="/help-center/">
				<SEO title={post.title} description={post.acf.seo_description} />
				<div className="d-flex justify-content-center">
					<div
						className="col-12 col-lg-11 col-xl-10 row my-4 d-flex justify-content-center"
						style={{ paddingTop: '16rem' }}
					>
						<div className="col-12 col-lg-4 p-0 m-0">
							<CategoryDrawer
								allPosts={allPosts}
								activePost={`/help-center/${post.acf.category}/${post.slug}`}
								activePostTag={post.acf.category}
							/>
						</div>

						<div className="col-12 col-lg-7 p-0 m-0">
							<Link to={'/help-center/'} className="link">
								<span className="icon back-blue-arrow mr-1"></span> Назад
							</Link>
							<h1
								className="my-3"
								dangerouslySetInnerHTML={{ __html: post.title }}
							></h1>
							<Breadcrumbs items={b} />

							<div className="text-center">
								<img
									src={post.featured_image_url}
									className="img-fluid full-width"
									alt={post.title}
								/>
							</div>
							<div
								className="mt-5"
								dangerouslySetInnerHTML={{ __html: post.content }}
							/>
							<ShareTab
								path={`/help-center/${post.acf.category}/${post.slug}`}
							/>
						</div>
					</div>
				</div>
			</Layout>
		</BgWrapper>
	);
};

export const query = graphql`
	query($id: String!) {
		allPosts: allWordpressWpHelp {
			edges {
				node {
					title
					slug
					acf {
						category
					}
				}
			}
		}
		post: wordpressWpHelp(id: { eq: $id }) {
			title
			content
			featured_image_url
			slug
			acf {
				seo_description
				category
			}
		}
		bg: file(relativePath: { eq: "help/help-single-bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
	}
`;
