import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 13px;
`;

export const Breadcrumbs = ({ items }) => (<div className='inline mb-3'>
  <Wrapper>
  {items.map(({ text, link }, index) => {
    return index === items.length - 1 ? <span dangerouslySetInnerHTML={{ __html: text }}></span> : <Link to={link} className='link' dangerouslySetInnerHTML={{ __html: text + '/' }}></Link>;
  })}
  </Wrapper>
</div>);
